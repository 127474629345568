import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

window.Apex = {
  chart: {
    fontFamily: 'HelveticaNeueLTStd, sans-serif',
  },
  noData: {
    text: 'Keine Daten vorhanden',
    align: 'center',
    verticalAlign: 'middle',
  },
  colors: ['#00c8a0', '#004994', '#ffc800', '#ff8c28', '#f05000'],
}

Vue.component('apexchart', VueApexCharts)
