export default {
  state: {
    documentsFilter: null,
  },
  mutations: {
    SET_FILTER(state, newFilter) {
      state.documentsFilter = newFilter
    },
  },
  actions: {
    setFilter({ commit }, newFilter) {
      commit('SET_FILTER', newFilter)
    },
  },
}
