import axios from 'axios'

export default {
  state: {
    currencies: [],
    priceLists: [],
    insuredData: [],
    residences: [],
    deductibleStates: [],
    clients: [],
    processStates: [],
    careTypes: [],
    contactPersons: [],
    healthInsuranceCompanies: [],
    invoiceAddresses: [],
    deliveryAddresses: [],
  },
  mutations: {
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies
    },
    SET_PRICE_LISTS(state, priceLists) {
      state.priceLists = priceLists
    },
    SET_INSURED_DATA(state, insuredData) {
      state.insuredData = insuredData
    },
    SET_RESIDENCES(state, residences) {
      state.residences = residences
    },
    SET_DEDUCTIBLE_STATES(state, deductibleStates) {
      state.deductibleStates = deductibleStates
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_PROCESS_STATES(state, processStates) {
      state.processStates = processStates
    },
    SET_CARE_TYPES(state, careTypes) {
      state.careTypes = careTypes
    },
    SET_CONTACT_PERSONS(state, contactPersons) {
      state.contactPersons = contactPersons
    },
    SET_HEALTH_INSURANCE_COMPANIES(state, healthInsuranceCompanies) {
      state.healthInsuranceCompanies = healthInsuranceCompanies
    },
    SET_INVOICE_ADDRESSES(state, invoiceAddresses) {
      state.invoiceAddresses = invoiceAddresses
    },
    SET_DELIVERY_ADDRESSES(state, deliveryAddresses) {
      state.deliveryAddresses = deliveryAddresses
    },
    ADD_INVOICE_ADDRESS(state, invoiceAddress) {
      state.invoiceAddresses.push(invoiceAddress)
    },
    ADD_DELIVERY_ADDRESS(state, deliveryAddress) {
      state.deliveryAddresses.push(deliveryAddress)
    },
  },
  actions: {
    async getAllCurrencies({ commit }) {
      await axios
        .get('/v1/crm/document/information/currencies')
        .then((response) => {
          commit('SET_CURRENCIES', response.data)
        })
    },
    async getAllPriceLists({ commit }, { formId, lfdnr }) {
      await axios
        .get('/v1/crm/document/information/priceLists', {
          params: {
            formId: formId,
            lfdnr: lfdnr,
          },
        })
        .then((response) => {
          commit('SET_PRICE_LISTS', response.data)
        })
    },
    async getAllInsuredDataForAddress({ commit }, { lfdnr }) {
      await axios
        .get('/v1/crm/document/information/insuredData', {
          params: {
            lfdnr: lfdnr,
          },
        })
        .then((response) => {
          commit('SET_INSURED_DATA', response.data)
        })
    },
    async getAllResidences({ commit }) {
      await axios
        .get('/v1/crm/document/information/residences')
        .then((response) => {
          commit('SET_RESIDENCES', response.data)
        })
    },
    async getAllDeductibleStates({ commit }) {
      await axios
        .get('/v1/crm/document/information/deductibleStates')
        .then((response) => {
          commit('SET_DEDUCTIBLE_STATES', response.data)
        })
    },
    async getAllClients({ commit }) {
      await axios
        .get('/v1/crm/document/information/clients')
        .then((response) => {
          commit('SET_CLIENTS', response.data)
        })
    },
    async getAllProcessStates({ commit }) {
      await axios
        .get('/v1/crm/document/information/processStates')
        .then((response) => {
          commit('SET_PROCESS_STATES', response.data)
        })
    },
    async getAllCareTypes({ commit }) {
      await axios
        .get('/v1/crm/document/information/careTypes')
        .then((response) => {
          commit('SET_CARE_TYPES', response.data)
        })
    },
    async getAllContactPersons({ commit }, { lfdnr }) {
      await axios
        .get('/v1/crm/document/information/contactPersons', {
          params: {
            lfdnr: lfdnr,
          },
        })
        .then((response) => {
          commit('SET_CONTACT_PERSONS', response.data)
        })
    },
    async getAllHealthInsuranceCompanies({ commit }) {
      await axios
        .get('/v1/crm/document/information/healthInsuranceCompanies')
        .then((response) => {
          commit('SET_HEALTH_INSURANCE_COMPANIES', response.data)
        })
    },
    setInvoiceAddresses({ commit }, { invoiceAddresses }) {
      commit('SET_INVOICE_ADDRESSES', invoiceAddresses)
    },
    addInvoiceAddress({ commit }, { invoiceAddress }) {
      commit('ADD_INVOICE_ADDRESS', invoiceAddress)
    },
    setDeliveryAddresses({ commit }, { deliveryAddresses }) {
      commit('SET_DELIVERY_ADDRESSES', deliveryAddresses)
    },
    addDeliveryAddress({ commit }, { deliveryAddress }) {
      commit('ADD_DELIVERY_ADDRESS', deliveryAddress)
    },
  },
}
