import { getDocuments } from '@/services/crm/addressService.js'

export default {
  state: {
    documents: [],
  },
  mutations: {
    CLEAR_DOCUMENTS(state) {
      state.documents = []
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents
    },
  },
  actions: {
    async loadDocuments({ commit }, lfdnr) {
      commit('CLEAR_DOCUMENTS')
      await getDocuments(lfdnr).then((documents) => {
        documents.forEach((element) => {
          element.printLoading = false
          element.name = `${element.shortType} ${element.nr}`
        })
        commit('SET_DOCUMENTS', documents)
      })
    },
  },
}
