<template>
  <v-expansion-panel
    :class="{ 'border-left-primary-3': sideBar === true }"
    :disabled="disabled"
    @click="clicked"
  >
    <v-expansion-panel-header
      v-if="titleType === 'default'"
      :class="{
        'primary--text': textColor === 'default',
        'primary--text': textColor === 'primary',
        'black--text': textColor === 'black',
        'success--text': textColor === 'success',
        'warning--text': textColor === 'warning',
        'error--text': textColor === 'error',
        'grey--text': textColor === 'grey',
        'grey lighten-3': bgColor === 'grey',
        white: bgColor === 'white',
      }"
    >
      <slot name="title"></slot>
    </v-expansion-panel-header>
    <v-expansion-panel-header
      v-else-if="titleType === 'headline'"
      class="font-weight-black text-h6"
      :class="{
        'primary--text': textColor === 'default',
        'primary--text': textColor === 'primary',
        'black--text': textColor === 'black',
        'success--text': textColor === 'success',
        'warning--text': textColor === 'warning',
        'error--text': textColor === 'error',
        'grey--text': textColor === 'grey',
        'grey lighten-3': bgColor === 'grey',
        white: bgColor === 'white',
      }"
    >
      <slot name="title"></slot>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      :id="!padding ? 'no-padding' : ''"
      class="pt-4"
      :class="{
        'grey lighten-3': bgColor === 'grey',
        white: bgColor === 'white',
      }"
    >
      <slot name="content"></slot>

      <div class="d-flex justify-end">
        <slot name="actions"></slot>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      default: 'default',
    },
    titleType: {
      type: String,
      default: 'default',
    },
    sideBar: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: 'grey',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clicked() {
      this.$emit('click')
    },
  },
}
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
#no-padding::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
