import axios from 'axios'

export default {
  state: {
    status: [],
    salespersons: [],
    branchOffices: [],
    warehouses: [],
    availableDocumentTypes: [],
    prescriptionStates: [],
    objects: [],
    doctors: [],
  },
  mutations: {
    SET_DOCUMENT_TYPES(state, documentTypes) {
      state.availableDocumentTypes = documentTypes
    },
    SET_STATUS(state, status) {
      state.status = status
    },
    SET_SALESPERSONS(state, salespersons) {
      state.salespersons = salespersons
    },
    SET_BRANCH_OFFICES(state, branchOffices) {
      state.branchOffices = branchOffices
    },
    SET_WAREHOUSES(state, warehouses) {
      state.warehouses = warehouses
    },
    SET_PRESCRIPTION_STATES(state, warehouses) {
      state.prescriptionStates = warehouses
    },
    SET_OBJECTS(state, objects) {
      state.objects = objects
    },
    SET_DOCTORS(state, doctors) {
      state.doctors = doctors
    },
    ADD_DOCTOR(state, doctor) {
      state.doctors.push(doctor)
    },
  },
  actions: {
    async getAllowedDocumentTypes({ commit }, { lfdnr }) {
      await axios
        .get('/v1/crm/document/information/types', {
          params: { lfdnr: lfdnr },
        })
        .then((response) => {
          commit('SET_DOCUMENT_TYPES', response.data)
        })
    },
    async getAllDocumentStatus({ commit }) {
      await axios
        .get('/v1/crm/document/information/status')
        .then((response) => {
          console.log(response.data)
          commit('SET_STATUS', response.data)
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
        })
    },
    async getAllSalespersons({ commit }) {
      await axios
        .get('/v1/crm/document/information/salespersons')
        .then((response) => {
          commit('SET_SALESPERSONS', response.data)
        })
    },
    async getAllBranchOffices({ commit }) {
      await axios
        .get('/v1/crm/document/information/branch-offices')
        .then((response) => {
          commit('SET_BRANCH_OFFICES', response.data)
        })
    },
    async getAllWarehouses({ commit }) {
      await axios
        .get('/v1/crm/document/information/warehouses')
        .then((response) => {
          commit('SET_WAREHOUSES', response.data)
        })
    },
    async getAllPrescriptionStates({ commit }) {
      await axios
        .get('/v1/crm/document/information/prescriptionStates')
        .then((response) => {
          commit('SET_PRESCRIPTION_STATES', response.data)
        })
    },
    async getAllObjectsForAddress({ commit }, { lfdnr }) {
      await axios
        .get('/v1/crm/document/information/objects', {
          params: { lfdnr: lfdnr },
        })
        .then((response) => {
          commit('SET_OBJECTS', response.data)
        })
    },
    setDoctors({ commit }, { doctors }) {
      commit('SET_DOCTORS', doctors)
    },
    addDoctor({ commit }, { doctor }) {
      commit('ADD_DOCTOR', doctor)
    },
  },
}
