<template>
  <div>
    <v-app-bar app color="white" class="elevation-0" height="75">
      <!--Search Bar-->
      <v-app-bar-nav-icon
        v-if="loggedIn && miniNavbar"
        @click="openDrawer"
      ></v-app-bar-nav-icon>

      <BreadCrumbComponent v-if="!isMobile()"></BreadCrumbComponent>

      <v-spacer></v-spacer>
      <div class="mt-8 col-md-5 col-lg-3">
        <SearchComponent v-if="searchBarVisible"></SearchComponent>
      </div>

      <NotificationComponent v-if="loggedIn" />

      <v-menu v-if="loggedIn" offset-y min-height="800px">
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="primary rounded-pill fluid"
            style="
              display: grid;
              place-items: center;
              grid-template-columns: 2fr 0fr;
            "
            v-on="on"
          >
            <span
              v-if="!isMobile()"
              class="text-h6 font-weight-bold text-center white--text ml-2 px-4 py-3"
            >
              {{ user.username }}
            </span>
            <v-avatar class="elevation-0" size="54"
              ><img :src="user_img_base64" alt="Profilbild"
            /></v-avatar>
          </div>
        </template>
        <div class="white px-4" style="height: 330px">
          <v-card-title class="headline justify-center pt-8">
            <v-avatar size="100px">
              <img :src="user_img_base64" alt="Profilbild" />
            </v-avatar>
          </v-card-title>
          <v-card-subtitle class="mt-4 text-h5 font-weight-bold text-center">
            {{ user.username }}
          </v-card-subtitle>
          <v-card-text class="text-center text-h6 grey--text text--darken-3"
            >{{ user.email }}
          </v-card-text>
          <v-card-actions class="justify-center mb-4">
            <v-btn
              color="primary darken-1"
              class="rounded-pill px-8"
              text
              outlined
              @click="logout"
            >
              Abmelden
            </v-btn>
          </v-card-actions>
        </div>
      </v-menu>

      <v-btn
        v-if="loginButtonVisible"
        elevation="2"
        large
        :to="{ name: 'Login' }"
        >Login</v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NotificationComponent from '@/components/core/NotificationComponent.vue'
import SearchComponent from '@/components/home/SearchComponent.vue'
import BreadCrumbComponent from '@/components/core/BreadCrumbComponent.vue'

export default {
  components: {
    NotificationComponent,
    SearchComponent,
    BreadCrumbComponent,
  },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedIn', 'user', 'user_img_base64']),
    loginButtonVisible() {
      if (!this.loggedIn && this.$route.name != 'Login') {
        return true
      }
      return false
    },
    searchBarVisible() {
      if (
        this.$route.name == 'Home' ||
        this.$route.name == 'Login' ||
        !this.loggedIn
      ) {
        return false
      } else {
        return true
      }
    },

    miniNavbar() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm' ||
        this.$vuetify.breakpoint.name == 'md'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    drawer(newVal) {
      this.$emit('drawerChange', newVal)
    },
  },
  methods: {
    openDrawer() {
      this.$emit('drawerChange', true)
    },
    isMobile() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm'
      ) {
        return true
      } else {
        return false
      }
    },
    logout() {
      this.$store.dispatch('logout')
    },
    navigateToHome() {
      this.$router.push('/')
    },
  },
}
</script>
