import axios from 'axios'

export async function getGenders() {
  const response = await axios.get('/v1/util/genders')

  return response.data
}

export function predictGenderBasedOnFirstName(firstName) {
  return axios.get('/v1/util/autofill/gender', {
    params: { name: firstName },
  })
}
