import axios from 'axios'

export default {
  state: {
    deliveryTypes: [],
    deliveryConditions: [],
    billingTypes: [],
    billingConditions: [],
  },
  mutations: {
    SET_DELIVERY_TYPES(state, deliveryTypes) {
      state.deliveryTypes = deliveryTypes
    },
    SET_DELIVERY_CONDITIONS(state, deliveryConditions) {
      state.deliveryConditions = deliveryConditions
    },
    SET_BILLING_TYPES(state, billingTypes) {
      state.billingTypes = billingTypes
    },
    SET_BILLING_CONDITIONS(state, billingConditions) {
      state.billingConditions = billingConditions
    },
  },
  actions: {
    async getAllDeliveryTypes({ commit }) {
      await axios
        .get('/v1/crm/document/information/deliveryTypes')
        .then((response) => {
          commit('SET_DELIVERY_TYPES', response.data)
        })
    },
    async getAllDeliveryConditions({ commit }) {
      await axios
        .get('/v1/crm/document/information/deliveryConditions')
        .then((response) => {
          commit('SET_DELIVERY_CONDITIONS', response.data)
        })
    },
    async getAllBillingTypes({ commit }) {
      await axios
        .get('/v1/crm/document/information/billingTypes')
        .then((response) => {
          commit('SET_BILLING_TYPES', response.data)
        })
    },
    async getAllBillingConditions({ commit }) {
      await axios
        .get('/v1/crm/document/information/billingConditions')
        .then((response) => {
          commit('SET_BILLING_CONDITIONS', response.data)
        })
    },
  },
}
