export default {
  state: {
    minSizes: {
      xxs: {
        CLOCK: {
          minW: 1,
          minH: 5,
        },
        TASKS: {
          minW: 2,
          minH: 5,
        },
        APPOINTMENTS: {
          minW: 3,
          minH: 4,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 2,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 1,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 1,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 2,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 2,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 2,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 2,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 2,
          minH: 3,
        },
      },
      xs: {
        TASKS: {
          minW: 4,
          minH: 4,
        },
        APPOINTMENTS: {
          minW: 4,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 4,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 2,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 2,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 3,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 4,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 3,
          minH: 3,
        },
      },
      sm: {
        TASKS: {
          minW: 3,
          minH: 4,
        },
        APPOINTMENTS: {
          minW: 3,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 3,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 2,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 3,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 3,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 4,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 4,
          minH: 3,
        },
      },
      md: {
        TASKS: {
          minW: 4,
          minH: 3,
        },
        APPOINTMENTS: {
          minW: 4,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 4,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 3,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 3,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 4,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 4,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 5,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 5,
          minH: 3,
        },
      },
      lg: {
        TASKS: {
          minW: 4,
          minH: 4,
        },
        APPOINTMENTS: {
          minW: 3,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 3,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 2,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 3,
          minH: 3,
        },
        STATISTIC_MONTH: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 3,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 3,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 4,
          minH: 3,
        },
      },
    },
  },
  getters: {
    minSizes: (state) => state.minSizes,
  },
}
