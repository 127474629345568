<template>
  <v-card
    flat
    :outlined="outlined"
    :class="bordered ? 'border-left-primary-3' : 'rounded-0'"
  >
    <slot name="image"></slot>
    <v-card-title>
      <slot name="title">
        {{ title }}
      </slot>
    </v-card-title>

    <v-card-subtitle>
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </v-card-subtitle>

    <v-card-text>
      <slot></slot>
      <slot name="content"> </slot>
    </v-card-text>

    <v-card-actions class="d-flex justify-end flex-grow-1 align-end">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
