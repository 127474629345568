import { getGenders } from '@/services/util/genderService.js'

export default {
  state: {
    genders: [],
  },
  mutations: {
    SET_GENDERS(state, genders) {
      state.genders = genders
    },
  },
  actions: {
    loadGenders({ commit }) {
      getGenders().then((response) => commit('SET_GENDERS', response))
    },
  },
}
