import { getApps } from '@/services/core/appService.js'

export default {
  state: {
    availableApps: [],
    categories: [],
    shaking: false,
  },
  mutations: {
    SET_AVAILABLE_APPS(state, availableApps) {
      state.availableApps = availableApps
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
  },
  actions: {
    async loadAppsAvailableForCurrentUser({ commit }) {
      return await getApps().then((apps) => {
        // Filter out apps that have false set in show_in_menu
        const filteredApps = apps

        commit('SET_AVAILABLE_APPS', filteredApps)
      })
    },
    async ensureAvailableAppsLoaded({ state, dispatch }) {
      if (state.availableApps == null || state.availableApps.length == 0) {
        await dispatch('loadAppsAvailableForCurrentUser')
      }
    },
    setAppCategories({ commit }, categories) {
      commit('SET_CATEGORIES', categories)
    },
  },
  getters: {
    availableApps(state) {
      return state.availableApps
    },
    appCategories(state) {
      return state.categories
    },
    getAppsForCategory: (state) => (categoryId) => {
      let filteredApps = state.availableApps.filter(
        (app) => app.appCategory == categoryId && app.showInMenu
      )
      return filteredApps
    },
    getAppsWithSettings(state) {
      let filteredApps = state.availableApps.filter((item) => item.webSettings)
      console.debug(filteredApps)

      return filteredApps
    },
    userHasAppById: (state) => (appId) => {
      return (
        state.availableApps.filter((item) => item.appId === appId).length != 0
      )
    },
  },
}
