import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)
import de from 'vuetify/lib/locale/de'

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#13a0cb',
        primaryLight: '#bbecfa',
        secondary: '#13a0cb',
        accent: '#8C345B',
        error: '#eb0050',
        success: '#78A000',
        warning: '#FF8C28',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de',
  },
})
